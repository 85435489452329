/** What's Weird: Step by Step Video **/

import React from "react";
import { gsap } from "gsap"
import FullscreenVideo from "../../components/media/fullscreen-video"
import { preloadImages, preloadAudio } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"


let globalState, data, preloadData;

class WhatsWeirdPage1 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[1];
    preloadData = globalState.assets[2];

    //refs
    this.bgVideoRef = this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
  }


  componentDidMount = () => {

    setTimeout(() => {
      this.bgVideoRef.playVideo();
    }, globalState.transitionDuration);
    
    //add page base track
    globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1); //VO

    //update volume of base track
    globalState.baseAudioRef.updateVolume(2, 90);

    //position text
    positionText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);

    //show text
    gsap.to(this.refs.text1Ref, 0, { autoAlpha: 1.0 });



    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.text1,
      globalState.cdnUrl + preloadData.background,
      globalState.cdnUrl + preloadData.slide1,
      globalState.cdnUrl + preloadData.slide1Highlight,
      globalState.cdnUrl + preloadData.slide2,
      globalState.cdnUrl + preloadData.slide2Highlight,
      globalState.cdnUrl + preloadData.slide3,
      globalState.cdnUrl + preloadData.slide3Highlight,
      globalState.cdnUrl + preloadData.slide4,
      globalState.cdnUrl + preloadData.slide4Highlight,
      globalState.cdnUrl + preloadData.slide5,
      globalState.cdnUrl + preloadData.slide5Highlight,
    ], this.preloadImgCallback);

  }

  preloadImgCallback = () => {
    //this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
    const preloadData = globalState.assets[2];
    preloadAudio([
        globalState.cdnUrl + preloadData.slideSound1, 
        globalState.cdnUrl + preloadData.slideSound2,
        globalState.cdnUrl + preloadData.slideSound3,
        globalState.cdnUrl + preloadData.slideSound4,
        globalState.cdnUrl + preloadData.slideSound5,
        globalState.cdnUrl + preloadData.slideSound6
    ], this.preloadAudioCallback);

  }
  preloadAudioCallback = () => {
      this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }



  componentWillUnmount() {
    this.bgVideoRef.pauseVideo();

    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);

    //remove base track
    //globalState.baseAudioRef.updateTrack(1, false);

  }

  bgVideoEnded = () => {
    console.log("Bg Video ended");
    if(this.bgVideoRef){
      //this.bgVideoRef.seekVideo(0);
    }
  }

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({ textVisible: !this.state.textVisible });

    //reset the animation delay
    resetAnimationDelay();

    if (this.state.textVisible) {
      //show text
      showText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);

    } else {
      //hide text
      hideText(this.refs.text1Ref, "left");
    }
  }



  isTransitioning = () => {
    this.bgVideoRef.pauseVideo();
  }



  render() {

    return (

      <>

        <link rel="prefetch" href="/whats-weird/2" />

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/whats-weird/2" prevLocation="/whats-weird"
          isTransitioning={this.isTransitioning} />

        <div className="fullpage-wrapper">
          <SEO title="Pine Point - What's Weird" />

          {data.bgVideo ?
            <FullscreenVideo file={globalState.videoUrl + data.bgVideo}
              poster={globalState.cdnUrl + data.bgVideoPoster} fullscreenClass={'fullscreen-video'} autoPlay={false} loop={true} muted={true} ended={this.bgVideoEnded} ref={div => this.bgVideoRef = div} globalState={globalState} />
            : ""
          }

          
          <img src={globalState.cdnUrl + data.line} alt="" className="black-line" />
          <img className="caption" src={globalState.cdnUrl + data.caption} alt="1987/2009" style={{top: '70px', right: '30px', position: 'absolute', zIndex: 1}} />

          <div className="text-wrapper">
            <div className="text text--left-top" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginLeft: "0", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="YOU KNOW WHAT’S WEIRD? When you decide to get rid of a town, there are odd considerations and effects. For instance, once it’s gone, has it really, truly disappeared?" />
            </div>
          </div>

        </div>

      </>
    );
  }


};

export default WhatsWeirdPage1
